<template>
  <v-icon :color="iconColorClass">
    <slot>
      {{ iconProjects }}
    </slot>
  </v-icon>
</template>

<script>
// design
import { iconProjects } from "@/design/icon/iconConst";

// model
import {
  findProjectStateColorClass,
  projectOverdueColorClass
} from "@/model/workflow/project/projectModel";

export default {
  name: "ProjectIcon",
  data() {
    return {
      iconProjects: iconProjects
    };
  },
  props: {
    /**
     * @type {{projectId: Number, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}}
     */
    project: undefined
  },
  computed: {
    /**
     * icon Color Class
     * @return {String|string|undefined}
     */
    iconColorClass() {
      if (!this.project) {
        return undefined;
      }

      if (this.project?.isOverdue ?? false) {
        return projectOverdueColorClass;
      }

      return (
        findProjectStateColorClass(this.project?.status ?? -1) ?? undefined
      );
    }
  }
};
</script>
